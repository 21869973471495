import React from 'react';

export const LoginError = (props) => {
    return (
        <div className='loadingContainer' >
            <div className='loadingImage' style={{ marginTop: '-48px' }}>
                <img className={'error'} src='warningIcon.png' alt='error icon'  />
                <h1 style={{ color: '#fff', fontWeight: '100', fontSize: '48px' }}>Login Error</h1>
                <p style={{ color: '#fff', fontWeight: '100', fontSize: '24px', opacity:.65 }}>An error occurred and your login request could not be completed.</p>
            </div>
        </div>

    )
}