import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import Debug from './debug';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useOktaAuth } from '@okta/okta-react';

export const checkResponseForJSON = (response) => {
    const contentType = response.headers.get("content-type");
    let isJSON = (contentType && contentType.indexOf("application/json") !== -1);
    Debug({ isJSONResponse: isJSON });
    return isJSON
}

//const getCleanError = (error) => {
//    if (error instanceof String && error.includes('<!DOCTYPE html>')) {
//        let parser = new DOMParser();
//        let errorDoc = parser.parseFromString(error, 'text/html');
//        let html = errorDoc.getElementsByTagName('body')[0].innerText.replace(/(\r\n|\n|\r)/gm, '');
//        Debug({ html })
//        return html;
//    }

//    let cleanedErrorJson = error.replace(/(\r\n|\n|\r)/gm, "|").replace('||', '|');
    
//    try {
//        let cleanedErrorObj = JSON.parse(cleanedErrorJson);
//        if (cleanedErrorObj.errors) {
//            let errorString = '';
//            for (var propertyName in cleanedErrorObj.errors) {
//                cleanedErrorObj.errors[propertyName].map((e, index) => {
//                    errorString += `[${propertyName}] ${e}|`
//                })

//            }
//            return errorString;
//        } else {
//            return cleanedErrorObj.error;
//        }
//    }
//    catch (e) {
//        return cleanedErrorJson;
//    }
//}

//const getErrorHtml = (error) => {


//    let cleanedError = getCleanError(error);
//    Debug({ cleanedError: cleanedError })
//    if (cleanedError.includes('|')) {
//        let cleanedArray = cleanedError.split('|');
//        let cleanError = cleanedArray.map((e, index) => <div key={index}>{e}</div>)
//        return <Fragment>{cleanError}</Fragment>;
//    }
//    return <Fragment>{cleanedError}</Fragment>;
//}

//const getErrorHtmlFromJson = (e) => {

//    let error = JSON.parse(e);
//    try {
//        error = JSON.parse(e)
//    }
//    catch (x) {}
//    try {
//        error = JSON.parse(e)
//    }
//    catch (x1) { }

//    return <Fragment>
//        <div style={{ fontSize: '16px', fontWeight: 300, }}><FontAwesomeIcon icon={faExclamationTriangle} /> <span style={{ marginLeft: '16px' }}>{error.message}</span></div>
//        {error.stack ? < div style={{ fontSize: '14px', opacity: '.75', borderTop: '1px solid #fff', paddingTop: '16px', marginTop: '16px' }}>{error.stack}</div> : null }
//    </Fragment>

//}

export const getToken = () => {
    var token = sessionStorage.getItem('cci_token');
    return token;
}

//export const fetchData = (url, method, body, successMsg, errorMsg, successCallback, errorCallback) => {

//    let headers = {
//        'Accept': 'application/json',
//        'Content-Type': 'application/json',
//    };

//    let token = getToken();
//    if (token) headers.Authorization = `Bearer ${token}`;
//    if (process.env.REACT_APP_SSO_ID) headers.SSO_ID = process.env.REACT_APP_SSO_ID;

//    Debug(`Performing a ${method} call to ${url}`);
//    Debug({ payload: body });
//    fetch(url, {
//        method: method,
//        headers: headers,
//        body: body,
//        credentials: 'include',
//    })
//        .then(response => {
//            if (checkResponseForJSON(response) && response.ok) {
//                response.json()
//                    .then(data => {
//                        Debug(`Successfully performed a ${method} call to ${url} and returned a JSON result`);
//                        if (successMsg) toast.success(successMsg, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
//                        if (successCallback) successCallback(data);
//                    }).catch(error => {
//                        Debug(`An error occurred while parsing JSON data.`)
//                        toast.error(<Fragment>{getErrorHtml(error)}</Fragment>, { position: toast.POSITION.TOP_CENTER, autoClose: false });
//                        if (errorCallback) errorCallback();
//                    });
//            } else {
//                    response.text()
//                        .then(error => {
//                            Debug(`${method.toUpperCase()} method call returned JSON formatted error.`)
//                            if (response.status === 401) {
//                                let e = JSON.stringify({ message: 'Unable to authenticate OnePass user' });
//                                toast.error(<Fragment>{getErrorHtmlFromJson(e)}</Fragment>, { position: toast.POSITION.TOP_CENTER, autoClose: false })
//                            } else {
//                                toast.error(<Fragment>{getErrorHtmlFromJson(error)}</Fragment>, { position: toast.POSITION.TOP_CENTER, autoClose: false });
//                            }
//                            if (errorCallback) errorCallback();
//                        })
//                        .catch(error => {
//                            Debug(`An error occurred while parsing JSON error data.`)
//                            toast.error(<Fragment>{getErrorHtml(error)}</Fragment>, { position: toast.POSITION.TOP_CENTER, autoClose: false });
//                            if (errorCallback) errorCallback();
//                        });
//            } 
//        })



//}

export const fetchData = (url, method, body, successMsg, errorMsg, successCallback, errorCallback, oktaToken) => {

    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };

    let userToken = getToken();
    let token = oktaToken;
    if (oktaToken) {
        headers.Authorization = `Bearer ${token}`;
    } else {
        headers.Authorization = `Bearer ${userToken}`;
    }

    //if (userToken) headers.UserToken = `Bearer ${userToken}`;
    if (process.env.REACT_APP_SSO_ID) headers.SSO_ID = process.env.REACT_APP_SSO_ID;


    Debug(`Performing a ${method} call to ${url}`);
    Debug({ payload: body });
    fetch(url, {
        method: method,
        headers: headers,
        body: body,
        credentials: 'include',
    })
    .then(response => {
        if (checkResponseForJSON(response) && response.ok) {
            response.json()
                .then(data => {
                    Debug(`Successfully performed a ${method} call to ${url} and returned a JSON result`);
                    if (successMsg) toast.success(successMsg, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                    if (successCallback) successCallback(data);
                }
                ).catch(error => {
                    Debug(`An error occurred while parsing JSON data. ${error}`)
                    toast.error(<Fragment>{error}</Fragment>, { position: toast.POSITION.TOP_CENTER, autoClose: false });
                    if (errorCallback) errorCallback();
                });
        } else if (!response.ok) {
            response.json()
                .then(error => {
                    Debug({ Error: error?.message });
                    Debug({ Details: error?.stack });
                    if (errorCallback) errorCallback();
                    toast.error(<Fragment>
                        <div style={{ fontSize: '16px', fontWeight: 300, }}><FontAwesomeIcon icon={faExclamationTriangle} /> <span style={{ marginLeft: '16px' }}>{error.message}</span></div>
                        {error.stack ? <div style={{ fontSize: '14px', opacity: '.75', borderTop: '1px solid #fff', paddingTop: '16px', marginTop: '16px' }}>{error.stack}</div> : null}
                    </Fragment>, { position: toast.POSITION.TOP_CENTER, autoClose: false });

                });
        } else {
            response.text()
                .then(error => {
                    Debug({error });
                    if (errorCallback) errorCallback();
                    toast.error(<Fragment>
                        <div style={{ fontSize: '16px', fontWeight: 300, }}><FontAwesomeIcon icon={faExclamationTriangle} /> <span style={{ marginLeft: '16px' }}>{error}</span></div>     
                    </Fragment>, { position: toast.POSITION.TOP_CENTER, autoClose: false });

                });
        }
    })



}