export const numberWithCommas = (x) => {
    let value = (x.toString()).split(/(?=(?:\d{3})+(?:\.|$))/g).join(",");
    return value.toString().replace('$,','$');
}

export const isNullOrWhitespace = (str) =>  {
    return str === undefined || str === null || str.match(/^ *$/) !== null;
}

export const isGuid = (stringToTest) => {
    if (stringToTest[0] === "{") {
        stringToTest = stringToTest.substring(1, stringToTest.length - 1);
    }
    var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    return regexGuid.test(stringToTest);
}

export const isOnePassId = (str) => {
    if (isNullOrWhitespace(str)) return false;
    return (isGuid(str));
}