import React from 'react';

export const Loading = (props) => {
    return (
        <div className='loadingContainer'>
            <div className='loadingImage'>
                <img src='loading.gif' alt='Regions bike loading icon' />
                <p>{props.text}</p>
            </div>
        </div>

    )
}