import React, { Component, Fragment } from 'react';
import SearchForm from '../components/searchForm';
import Debug from '../components/debug';
import { fetchData } from '../components/fetchData';
import ChecksList from '../components/checksList';
import { Loading } from '../components/loading';
import { withRouter } from 'react-router';
import { Layout } from '../components/Layout';
export class Checks extends Component {

    constructor(props) {
        super(props);
        this.state = { checks: [], orderBy : 'IPAmount', sortAsc : false, searching: false }
    }


    sort = (colClicked) => {
        let order = JSON.parse(JSON.stringify(this.state.orderBy));
        let dir = JSON.parse(JSON.stringify(this.state.sortAsc));
        let checks = JSON.parse(JSON.stringify(this.state.checks));
        if (colClicked === this.state.orderBy) {
            dir = !dir;
        } else {
            order = colClicked;
            dir = true;
        }

        checks = this.sortChecks(checks, dir, order);
       
        this.setState({ checks: checks, orderBy: order, sortAsc: dir });
    }

    sortChecks(checks, sortAsc, order) {
        if (sortAsc) {
            return checks.sort((a, b) => (a[order] > b[order]) ? 1 : -1);
        } else {
            return checks.sort((a, b) => (a[order] > b[order]) ? -1 : 1);
        }
    }

    search = (searchItems) => {
        this.setState({searching: true}, () => {
                Debug({ searchItems });
                fetchData('checks/search', 'POST', JSON.stringify(searchItems), null, null, (data) => {
                    let checks = this.sortChecks(JSON.parse(data), this.state.sortAsc, this.state.orderBy);
                    this.setState({ checks: checks, searching: false });
                }, () => {
                    this.setState({ checks: [], searching: false })
                })
            }
        );       
    }

    clearTransactions = () => {
        this.setState({ checks: [], orderBy: 'IPAmount', sortAsc: false })
    }

    render() {
        return (
            <Layout {... this.props} version={process.env.REACT_APP_APPVERSION}>  
                <Fragment>

                    <h1>Search Image Archive for Paid Checks</h1>
                    <p>In order to maximize your research, please provide as much information as possible in the search criteria fields.</p>
                    <div id='searchWindow'>
                        <SearchForm search={this.search} clearTransactions={this.clearTransactions} accounts={this.props.user.PaidAccounts} />
                        {
                            this.state.searching ? <Loading text='Searching...' /> :
                                <ChecksList transactions={this.state.checks} sort={this.sort} orderBy={this.state.orderBy} sortAsc={this.state.sortAsc} />
                        }
                    </div>
                </Fragment>
                </Layout>
        )
    }
}


export default withRouter(Checks);