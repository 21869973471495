import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { withRouter } from 'react-router';

export const Nav = (props) => {
    let location = useLocation();
    return (
        <section id='nav'>
            <ul>
                <li className={location.pathname.includes('/checks/viewchecks') ? 'selected' : ''}><Link to='/checks/viewchecks'>Search Paid Checks</Link></li>
                <li className={location.pathname.includes('/deposits/viewdeposits') ? 'selected' : ''}><Link to='/deposits/viewdeposits'>Search Deposits</Link></li>
                <li className={location.pathname.includes('/downloads/viewdownloads') ? 'selected' : ''}><Link to='/downloads/viewdownloads'>Search Monthly Files</Link></li>
                <li className={location.pathname.includes('/logout') ? 'selected' : ''}><Link to='/logout'>Logout</Link></li>

            </ul>
        </section>
    )
}

export default withRouter(Nav);