import React, { Component, } from 'react';
import { withRouter } from 'react-router';
import { updateUser } from '../redux/actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withOktaAuth } from '@okta/okta-react';
import '../custom.css'

export class Logout extends Component {

    componentDidMount() {
        sessionStorage.setItem('cci_token', null);
        sessionStorage.setItem('cci_user', null);
        this.props.oktaAuth.tokenManager.clear();
        window.opener = null;
        window.open('', '_self');
        window.close();
        this.props.updateUser({})
    }

    render() {
        return (
            <div className='loadingContainer'>
                <div className='loadingImage' style={{ textAlign: 'center' }}>
                    <img src='logo.png' alt='Regions logo' style={{ width: '220px', height: '34px' }} />
                    <p style={{ marginTop: '-2px', wordSpacing: '2px' }}>Successfully Logged Out</p>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = {
    updateUser
}

export default withOktaAuth(compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Logout));

