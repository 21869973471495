const envSettings = window;

export default  {  
  react_app_appversion: envSettings.REACT_APP_APPVERSION,
  react_app_apiurl: envSettings.REACT_APP_APIURL,
  react_app_client_id: envSettings.REACT_APP_CLIENT_ID,
  react_app_okta_domain: envSettings.REACT_APP_OKTA_DOMAIN,
  react_app_issuer: envSettings.REACT_APP_ISSUER,
  react_app_logout_uri: envSettings.REACT_APP_LOGOUT_URI,
  react_app_useokta: envSettings.REACT_APP_USEOKTA
};
