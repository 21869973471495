import React, { Component } from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import Debug from '../components/debug';
import { fetchData } from '../components/fetchData';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';

const selectStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '#777',
        backgroundColor: state.isSelected ? '#88bd40' : '#fff',
        ':hover': {
            color: '#66a326',
            backgroundColor: '#c2dd9e',
        }
    }),
    input: (styles) => ({
        ...styles,
        height: '34px !important',
        outline: 'none !important',
        borderColor: "#88bd40",
        ':hover': {
            borderColor: 'hsl(0,0%,80%) !important',
            borderWidth: '1px !important',
            boxShadow: 'none !important',
        },
        ':focus': {
            borderColor: 'hsl(0,0%,80%) !important',
            borderWidth: '1px !important',
            boxShadow: 'none !important',
        }
    }),
    control: (styles) => ({
        ...styles,
        height: '34px !important',
        outline: 'none !important',
        boxShadow: 'none',
        borderColor: 'hsl(0,0%,80%) !important',
        ':hover': {
            border: '1px solid hsl(0,0%, 80 %) !important',
            borderColor: 'hsl(0,0%,80%) !important',
            borderWidth: '1px !important',
            boxShadow: 'none !important',
        },
        ':focus': {
            borderColor: 'hsl(0,0%,80%) !important',
            borderWidth: '1px !important',
            boxShadow: 'none !important',
        }
    })
}
const defaultState = {
    loading: true, MonthlyOptions: [], selectedText: '', formValues: {
        MonthYear: ''
        }
}

export class DownloadSearchform extends Component {

    constructor(props) {
        super(props);
        this.state = this.getDefaultState();
    }

    componentDidMount() {
        fetchData('downloads/months', 'GET', null, null, null, (data) => {
            var options = JSON.parse(data);
            this.setState({ MonthlyOptions: options, loading: false }, () => Debug({ MonthlyOptions: this.state.MonthlyOptions }));
        }, null);
    }

    getDefaultState = () => {
        return JSON.parse(JSON.stringify(defaultState));
    }


    populateMonths(search) {
        if (search) {
            return this.state.MonthlyOptions.filter(i => i.label.toLowerCase().includes(search.toLowerCase()))
        }
        else {
            return this.state.MonthlyOptions;
        }

    }

    promiseOptions = (inputValue) =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(this.populateMonths(inputValue));
            }, 1000);
        });

    performSearch = () => {
        var searchValues = Object.assign({}, this.state.formValues);
        this.props.search(searchValues.MonthYear);
    }

    clearAll = () => {
        var formdata = JSON.parse(JSON.stringify(this.state.formValues));
        formdata.MonthYear = '';
        this.setState({ formValues: formdata, selectedText: '' }, this.props.clearFiles)
    }


    handleSelectChange = (propName, value) => {
        Debug({selectChanged:value})
        let currentState = this.state.formValues;
        currentState[propName] = value.value;
        this.setState({ formValues: currentState, selectedText: value.label }, () => Debug({ formValues: this.state.formValues, selectedText: this.state.selectedText }))
    }

    render() {
        return (
            <form>
                <div className='row'>
                    <div className='label'>Monthly Files:</div>
                    <div className='control no-border'>
                        <Select
                            name={'MonthYear'}
                            options={this.state.MonthlyOptions}
                            styles={selectStyles}
                            placeholder={''}
                            value={{
                                label: this.state.selectedText, value: this.state.formValues.MonthYear
                            }}
                            onChange={(e) => this.handleSelectChange('MonthYear', e)}
                        />

                    </div>
                </div>
                <div className='row'>
                    <div className={'spacer'}></div>
                    <div className='btn' onClick={this.clearAll}> <FontAwesomeIcon icon={faUndoAlt} /> Clear</div>
                    <div className='btn primary' onClick={this.performSearch}> <FontAwesomeIcon icon={faSearch} /> Search</div>
                </div>
            </form>
        )
    }
}


const mapStateToProps = state => ({
    user: state.user
});


export default compose(withRouter, connect(mapStateToProps, null))(DownloadSearchform);