import { combineReducers, createStore, applyMiddleware } from 'redux';
import { userReducer } from './actions.js';
import thunk from 'redux-thunk';

export const reducers = combineReducers({
    user: userReducer
});

export const store = createStore(
    reducers,
    applyMiddleware(thunk)
);

