import React, { Component, Fragment, useEffect } from 'react';
import { Route, withRouter, Switch } from 'react-router';

import { Home } from './components/Home';
import './custom.css'
import { Checks } from './pages/checks';
import { Deposits } from './pages/deposits';
import { Downloads } from './pages/downloads';
import { LoginError } from './pages/loginError';
import { ProvisionUser } from './pages/provisionUser';
import { updateUser } from './redux/actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Login from './pages/login';
import Debug from './components/debug';
import Logout from './pages/logout';
import { Unauthenticated } from './pages/unauthenticated';
import { Security, useOktaAuth, LoginCallback, SecureRoute } from '@okta/okta-react';
import config from './config';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { useHistory } from 'react-router-dom';


const nonRedirectUrls = [
    '/account/onepasslogin',
    '/logout',
    '/login/error',
    '/Account/OktaLogin'
];

export class App extends Component {

    oktaAuth = new OktaAuth(config.oidc);

    constructor(props) {
        super(props);
    }

    // returns true if we should redirect from current page to the onepasslogin page to set user data.
    shouldRedirect() {
        return (
            (this.props.user === undefined || this.props.user === null) &&
            !nonRedirectUrls.includes(this.props.history.location.pathname)
        );
    }

    componentDidMount() {
        // if (this.shouldRedirect()) {
        //     this.props.history.push('/account/onepasslogin');
        // }
        if ((sessionStorage.getItem('cci_token') !== this.props.user?.Token) || !this.props.user?.Token) {
            this.getUser();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // if (this.shouldRedirect()) {
        //     this.props.history.push('/account/onepasslogin');
        // }
        if (prevProps.user?.Token !== this.props.user?.Token) {
            Debug({ prev: prevProps.user, now: this.props.user });
            if ((sessionStorage.getItem('cci_token') !== this.props.user?.Token) || !this.props.user?.Token) {
                this.getUser();
            }
        }
    }
    
    onAuthResume = () => {
        this.props.history.push('/login');
    };
    
    restoreOriginalUri = (oktaAuth, originalUri) => {
        if (originalUri.includes('/users/viewuser/')) {
            this.props.history.replace(toRelativeUrl(originalUri, window.location.origin));
        }
        else {
            this.props.history.replace(toRelativeUrl('/account/onepasslogin', window.location.origin));
        }
    };

    getUser = () => {
        let sessionUser = sessionStorage.getItem('cci_user');
        Debug({ sessionUser })
        let parsedUser = JSON.parse(sessionUser);
        this.props.updateUser(parsedUser);
    } 

    render () {
        return (
            config.USE_OKTA ?
                <Security oktaAuth={this.oktaAuth} restoreOriginalUri={this.restoreOriginalUri}  >
                    <Switch>
                        <Fragment>
                            <Route path='/Account/OktaLogin' render={() => <LoginCallback {...this.props} onAuthResume={this.onAuthResume} />} />
                            <SecureRoute path='/account/onepasslogin' render={() => <Login {...this.props} />} />
                            <SecureRoute exact path='/logout' render={() => <Logout {...this.props} />} /> 
                            <SecureRoute exact path='/users/viewuser/:onePassId' render={() => <ProvisionUser {...this.props} oktaAuth={this.oktaAuth} requestingUser={this.props.user} />} />        
                            <SecureRoute exact path='/checks/viewchecks' render={() => <Checks {...this.props} />} />
                            <SecureRoute exact path='/deposits/viewdeposits' render={() => <Deposits {...this.props} />} />
                            <SecureRoute exact path='/downloads/viewdownloads' render={() => <Downloads {...this.props} />} />             
                            <Route path='/login/error' render={() => <LoginError {...this.props} />} />
                            <SecureRoute exact path='/' render={() => <Home {...this.props} />} />
                        </Fragment>
                    </Switch>
                </Security>
                :
                <Switch>
                    <Fragment>
                        <Route path='/Account/OktaLogin' render={() => <LoginCallback {...this.props} onAuthResume={this.onAuthResume} />} />
                        <Route path='/account/onepasslogin' render={() => <Login {...this.props} />} />
                        <Route exact path='/logout' render={() => <Logout {...this.props} />} /> 
                        <Route exact path='/users/viewuser/:onePassId' render={() => <ProvisionUser {...this.props} requestingUser={this.props.user} />} />        
                        <Route exact path='/checks/viewchecks' render={() => <Checks {...this.props} />} />
                        <Route exact path='/deposits/viewdeposits' render={() => <Deposits {...this.props} />} />
                        <Route exact path='/downloads/viewdownloads' render={() => <Downloads {...this.props} />} />             
                        <Route path='/login/error' render={() => <LoginError {...this.props} />} />
                        <Route exact path='/' render={() => <Home {...this.props} />} />
                    </Fragment>
                </Switch>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = {
    updateUser
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(App);
//export default App;