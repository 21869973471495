import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountDownAlt, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { getToken } from './fetchData';
import { Loading } from './loading';

export class FilesList extends Component {
    constructor(props) {
        super(props);
        this.state = { downloadBlobUrl: null, downloadFileName: '', downloading: false }
    }
   
    getSortIcon = (col) => {
        if (this.props.orderBy !== col) return;
        return this.props.sortAsc ? <FontAwesomeIcon icon={faSortAmountDownAlt} /> : <FontAwesomeIcon icon={faSortAmountDown} />
    }

    downloadFile = (bulkfile) => {
        let dlFileName = bulkfile.FileName.substring(bulkfile.FileName.lastIndexOf('/') + 1);
        console.log(dlFileName)
        this.setState({
            downloading: true, downloadFileName: dlFileName
        }, () => {
            let request = { Filename: bulkfile.FileName, YearMonth: `${this.props.yearMonth}` }
            let token = getToken();
            const options = {
                method: 'POST',
                body: JSON.stringify(request),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'SSO_ID': process.env.REACT_APP_SSO_ID
                }
            };
            fetch('downloads/file', options)
                .then(res => res.blob())
                .then(blob => {
                    

                    // IE & Edge fix for downloading blob files, gives option to save or open the file when the link is opened.
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        var fileName = this.state.downloadFileName.replace(/^.*[\\\/]/, '');
                        window.navigator.msSaveOrOpenBlob(blob, fileName);
                    } else {
                        var url = window.URL.createObjectURL(blob);
                        this.setState({ downloadBlobUrl: url })
                        var a = document.getElementById('hiddenDownloadLink');
                        a.click();
                        window.URL.revokeObjectURL(url);
                        
                    }
                    this.setState({ downloadBlobUrl: null, downloadFileName: '', downloading: false })
                });
        })

    }

    render() {
        return (
            <Fragment>
            <a id='hiddenDownloadLink' style={{ display: 'none' }} href={this.state.downloadBlobUrl} download={this.state.downloadFileName} />
            {this.state.downloading?
                    <Loading text={`Retrieving ${this.state.downloadFileName.replace(/^.*[\\\/]/, '')} for download ...`} />:
                <table>

                    <thead>
                        <tr>
                            <th onClick={() => this.props.sort('AccountNumber')}>Account # {this.getSortIcon('AccountNumber')}</th>
                            <th onClick={() => this.props.sort('FileName')}>Filename {this.getSortIcon('FileName')}</th>
                            <th onClick={() => this.props.sort('FileSize')}>Size {this.getSortIcon('FileSize')}</th>
                            <th onClick={() => this.props.sort('ItemCount')}>Items {this.getSortIcon('ItemCount')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.zipFiles && this.props.zipFiles.length > 0 ? this.props.zipFiles.map((z, index) =>
                            <Fragment key={index}>
                                <tr title='Click here to download this file' onClick={() => this.downloadFile(z)}>
                                    <td>{z.AccountNumber}</td>
                                    <td>{z.FileName.replace(/^.*[\\\/]/, '')}</td>
                                    <td>{z.FileSize}</td>
                                    <td>{z.ItemCount}</td>
                                </tr>
                            </Fragment>
                        ) : <tr className='no-hover'>
                                <td colSpan={4}> No files found.</td>
                            </tr>}
                    </tbody>
                </table>
                }
            </Fragment>
        )
    }
}