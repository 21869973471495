import React, { Component, Fragment } from 'react';
import SearchForm from '../components/searchForm';
import Debug from '../components/debug';
import { fetchData } from '../components/fetchData';
import DepositsList from '../components/depositsList';
import { Loading } from '../components/loading';
import { withRouter } from 'react-router';
import { Layout } from '../components/Layout';
export class Deposits extends Component {

    constructor(props) {
        super(props);
        this.state = { deposits: [], orderBy: 'IPAmount', sortAsc: false, searching: false }
    }

    sort = (colClicked) => {
        let order = JSON.parse(JSON.stringify(this.state.orderBy));
        let dir = JSON.parse(JSON.stringify(this.state.sortAsc));
        let deposits = JSON.parse(JSON.stringify(this.state.deposits));
        if (colClicked === this.state.orderBy) {
            dir = !dir;
        } else {
            order = colClicked;
            dir = true;
        }

        deposits = this.sortdeposits(deposits, dir, order);

        this.setState({ deposits: deposits, orderBy: order, sortAsc: dir });
    }

    sortdeposits(deposits, sortAsc, order) {
        if (sortAsc) {
            return deposits.sort((a, b) => (a[order] > b[order]) ? 1 : -1);
        } else {
            return deposits.sort((a, b) => (a[order] > b[order]) ? -1 : 1);
        }
    }

    search = (searchItems) => {
        this.setState({ searching: true }, () => {
                Debug({ searchItems });
            fetchData('deposits/search', 'POST', JSON.stringify(searchItems), null, null, (data) => {
                let deposits = this.sortdeposits(JSON.parse(data), this.state.sortAsc, this.state.orderBy);
                    this.setState({ deposits: deposits, searching: false });
            }, () => {
                    this.setState({ deposits: [], searching: false })
            })
            }
        );
    }

    clearTransactions = () => {
        this.setState({ deposits: [], orderBy: 'IPAmount', sortAsc: false })
    }

    updateTransaction = (index, t) => {
        Debug({ Items: t.DepositItems });
        let currentDeposits = JSON.parse(JSON.stringify(this.state.deposits));
        currentDeposits[index] = t;
        this.setState({ deposits: currentDeposits });
    }

    render() {
        return (
            <Layout {... this.props} version={process.env.REACT_APP_APPVERSION}>  
                <Fragment>
                    <h1>Search Image Archive for Deposits</h1>
                    <p>In order to maximize your research, please provide as much information as possible in the search criteria fields.</p>
                    <div id='searchWindow'>
                        <SearchForm search={this.search} clearTransactions={this.clearTransactions} accounts={this.props.user.DepositAccounts} isDeposits={true} />
                        {
                            this.state.searching ? <Loading text='Searching...' /> :
                                <DepositsList transactions={this.state.deposits} sort={this.sort} orderBy={this.state.orderBy} sortAsc={this.state.sortAsc} updateTransaction={this.updateTransaction} />
                        }
                    </div>
                </Fragment>
                </Layout>
        )
    }
}



export default withRouter(Deposits);


