import React, { Component, } from 'react';
import { withRouter } from 'react-router';
import { updateUser } from '../redux/actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import '../custom.css'

export class Unauthenticated extends Component {


    render() {
        return (
            <div className='loadingContainer'>
                <div className='loadingImage' style={{ textAlign: 'center' }}>
                    <img src='logo.png' alt='Regions logo' style={{ width: '220px', height: '34px' }} />
                    <p style={{ marginTop: '-2px', wordSpacing: '2px' }}>Not Authenticated</p>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = {
    updateUser
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Unauthenticated);

