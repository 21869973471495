import React, { Component } from 'react';
import { Layout } from './Layout';
import configuration from '../readConfig';

export class Home extends Component {
  static displayName = Home.name;

  render () {
      return (
          <Layout {... this.props} version={configuration.react_app_appversion}>  
            <div>
          
            </div>
          </Layout>
    );
  }
};
