import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';

export class Header extends Component {

    render() {
        return (
            <Fragment>
                <section id='header'>
                    <div className='logo'></div>
                    <div className='spacer'></div>
                    <div className='userDetails'>
                        <div className='username'>Welcome<span className='name'>{this.props.user?.FirstName} {this.props.user?.LastName}</span>
                            <div id='userPermissions'>
                                <div className='title'><FontAwesomeIcon icon={faUser} /> User Permissions:</div>
                                {this.props.user?.Checks ? <div className='permission'>VIEW PAID CHECKS</div> : null}
                                {this.props.user?.Deposits ? <div className='permission'>VIEW DEPOSITS</div> : null}
                                {this.props.user?.Download ? <div className='permission'>VIEW MONTHLY DOWNLOADS</div> : null}
                            </div>
                        </div>
                        <div className='version'>Version {this.props.version}</div>
                    </div>
                </section>
                <section id='appTitle'>
                    Commercial Check Image Archive
                </section>
            </Fragment>
        )
    }
}


