import configuration from './readConfig';

// Commenting the following lines as the values will be read from OCP config file

// const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '{clientId}';
// const ISSUER = process.env.REACT_APP_ISSUER || 'https://{yourOktaDomain}.com/oauth2/default';
// const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
// const REDIRECT_URI = `${window.location.origin}/Account/OktaLogin`;
// const USE_OKTA = process.env.REACT_APP_USEOKTA === 'true';

const CLIENT_ID = configuration.react_app_client_id || '{clientId}';
const ISSUER = configuration.react_app_issuer || 'https://{yourOktaDomain}.com/oauth2/default';
const OKTA_TESTING_DISABLEHTTPSCHECK = false;
const REDIRECT_URI = `${window.location.origin}/Account/OktaLogin`;
const LOGOUT_URI = configuration.react_app_logout_uri;
const USE_OKTA = configuration.react_app_useokta === 'true';


//const REDIRECT_URI = `https://checkimage.regionstest.com/Account/OktaLogin`;
// console.log ('CLIENT_ID values from config: ', CLIENT_ID );
// console.log ('Issuer values from config: ', ISSUER );
// console.log ('REDIRECT_URI values from config: ', REDIRECT_URI );
console.log ('USE_OKTA values from config: ', USE_OKTA );
console.log ('Value from secrets: ', process.env.REACT_APP_CLIENT_SECRET);


let USE_INTERACTION_CODE = false;
if (process.env.USE_INTERACTION_CODE === 'true') {
    USE_INTERACTION_CODE = true;
}

export default {
    oidc: {
        clientId: CLIENT_ID,
        issuer: ISSUER,
        redirectUri: REDIRECT_URI,
        scopes: ['openid', 'cci'],
        pkce: true,
        disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK === true,
        useInteractionCode: USE_INTERACTION_CODE === true,
        responseMode: 'query',
        postLogoutRedirectUri: LOGOUT_URI
    },
    USE_OKTA: USE_OKTA
};
