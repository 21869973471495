import React, { Component } from 'react';
import { Header } from './header';
import { Nav } from './nav';
import { withRouter } from 'react-router-dom';
import { Footer } from './footer.js';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <div>
            <Header {... this.props}/>
            <Nav  {... this.props}/>
            <section id='content'>
              {this.props.children}
            </section>
            <Footer />
      </div>
    );
  }
}

export default withRouter(Layout);
