import React, { Component, } from 'react';
import { withRouter } from 'react-router';
import '../custom.css'
import { fetchData } from '../components/fetchData';
import Debug from '../components/debug';
import jwt from 'jwt-decode';
import { Loading } from '../components/loading';
import { updateUser } from '../redux/actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isOnePassId } from '../components/sharedFunctions';
import { Redirect } from "react-router-dom";
import { LoginError } from './loginError';
import { withOktaAuth } from '@okta/okta-react';
import config from '../config';
import { toRelativeUrl } from '@okta/okta-auth-js';

export class Login extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = { userIsLoaded: false, accountsAreLoaded: false, isError: false}
        this.fetchUser = this.fetchUser.bind(this);
        this.deleteCookie = this.deleteCookie.bind(this);
        this.getCookie = this.getCookie.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchUser()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    deleteCookie = (name) => {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    getCookie = (name) => {
        // Split cookie string and get all individual name=value pairs in an array
        var cookieArr = document.cookie.split(";");

        // Loop through the array elements
        for (var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[i].split("=");

            /* Removing whitespace at the beginning of the cookie name
            and compare it with the given string */
            if (name == cookiePair[0].trim()) {
                // Decode the cookie value and return
                return decodeURIComponent(cookiePair[1]);
            }
        }

        // Return null if not found
        return null;
    }

    fetchUser = () => {


        if (this._isMounted && this.state.userIsLoaded === false) {

            //var id = this.props.match.params.onePassId;
            //Debug(`fetching user ${id}...`)
            //var url = isOnePassId(this.props.match.params.onePassId) ? `Authentication/${id}` : 'Authentication';
            var url = config.USE_OKTA ? 'Authentication/okta' : 'Authentication';
            if (this._isMounted) fetchData(url, "GET", null, null, "Unable to authenticate user.", (data) => {
                let token = data;
                if (data == null || data === undefined) {
                    sessionStorage.setItem('cci_token', null);
                    this.props.history.push('/logout');
                    return;
                }
                sessionStorage.setItem('cci_token', null);
                sessionStorage.setItem('cci_token', token);
                let user = jwt(token);
                user.Token = token;
                user.Id = user.unique_name;
                var isRegions = user.IsRegions === 'true';
                user.IsRegions = isRegions;
                this.setState({ userIsLoaded: true }, this.fetchAccounts(user))
            }, () => {
                this.props.history.push('/login/error');
            }, this.props.oktaAuth?.getIdToken())

        }
    }


    fetchAccounts = (user) => {
        //fetch deposit accounts
        fetchData('accounts/paid', 'GET', null, null, null, (data) => {
            user.PaidAccounts = JSON.parse(data);
            //fetch deposit accounts
            fetchData('accounts/deposits', 'GET', null, null, null, (data) => {
                user.DepositAccounts = JSON.parse(data);
                this.setState({ accountsAreLoaded: true }, () => {
                    //set session state
                    sessionStorage.setItem('cci_user', null);
                    sessionStorage.setItem('cci_user', JSON.stringify(user));
                    //update redux user
                    this.props.updateUser(user).then(() => {
                        //update url
                        this.props.history.replace(toRelativeUrl('/', window.location.origin));
                        //set loaded state
                    })
                })
            }, null, null)
        }, null, null)
    }

    render() {
        return (
            this.state.isError ?
            <LoginError /> :
            <Loading text={
                !this.state.userIsLoaded ? 'Retrieving user ...' :
                    !this.state.accountsAreLoaded ?
                        'Retrieving accounts ...' : 'Starting app ...'
            } /> 
                
        )
    }
}

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = {
    updateUser
}

export default withOktaAuth(compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Login));