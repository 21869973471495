import React, { Component } from 'react';
import { fetchData } from '../components/fetchData';
import { withRouter } from 'react-router';
import Select from 'react-select';
import { Loading } from '../components/loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Debug from '../components/debug';
import { faSave, faSquare, faCheckSquare, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import jwt from 'jwt-decode';
import { updateUser } from '../redux/actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withOktaAuth } from '@okta/okta-react';
import config from '../config';


const selectStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '#777',
        backgroundColor: state.isSelected ? '#88bd40' : '#fff',
        ':hover': {
            color: '#66a326',
            backgroundColor: '#c2dd9e',
        }
    }),
    input: (styles) => ({
        ...styles,
        height: '34px !important',
        outline: 'none !important',
        borderColor: "#88bd40",


        ':hover': {
            borderColor: 'hsl(0,0%,80%) !important',
            borderWidth: '1px !important',
            boxShadow: 'none !important',
        },
        ':focus': {
            borderColor: 'hsl(0,0%,80%) !important',
            borderWidth: '1px !important',
            boxShadow: 'none !important',
        }
    }),
    control: (styles) => ({
        ...styles,
        height: '34px !important',
        outline: 'none !important',
        boxShadow: 'none',
        borderColor: 'hsl(0,0%,80%) !important',
        ':hover': {
            border: '1px solid hsl(0,0%, 80 %) !important',
            borderColor: 'hsl(0,0%,80%) !important',
            borderWidth: '1px !important',
            boxShadow: 'none !important',
        },
        ':focus': {
            borderColor: 'hsl(0,0%,80%) !important',
            borderWidth: '1px !important',
            boxShadow: 'none !important',
        }
    })
}
const PermissionsEnum = {
    None: -1,
    Checks: 1,
    Deposits: 2,
    Download: 4
}


export class ProvisionUser extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = { editUser: null, loading: true, saving: false }
        this.performSearch = this.performSearch.bind(this);
        this.fetchUser = this.fetchUser.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.processToken = this.processToken.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;

        if (sessionStorage.getItem('cci_token') == null) {
            const query = new URLSearchParams(this.props.location.search);
            const token = query.get('token')
            if (token) { this.processToken(token); }
            else {
                this.fetchUser();
            }
        } else {
            Debug({ params: this.props.params })
            var id = window.location.pathname.split("/").pop();
            this.performSearch(id);
        }
    }

    processToken = (token) => {
        sessionStorage.setItem('cci_token', null);
        sessionStorage.setItem('cci_token', token);
        Debug({ token });
        let user = jwt(token);
        Debug({ user })
        user.Token = token;
        user.Id = user.unique_name;

        var isRegions = user.IsRegions === 'true';
        user.IsRegions = isRegions;
        //set session state
        sessionStorage.setItem('cci_user', null);
        sessionStorage.setItem('cci_user', JSON.stringify(user));

        //this.props.updateUser(user).then(() => {
        Debug({ params: this.props.params })
        //var id = this.props.match.params.onePassId;
        var id = window.location.pathname.split("/").pop()
        this.performSearch(id);
                //})


    }

    fetchUser = () => {


        if (this._isMounted) {

            var url = config.USE_OKTA ? 'Authentication/okta' : 'Authentication';
            if (this._isMounted) fetchData(url, "GET", null, null, "Unable to authenticate user.", (data) => {
                let token = data;
                if (data == null || data === undefined) {
                    sessionStorage.setItem('cci_token', null);
                    this.props.history.push('/logout');
                    return;
                }
                this.processToken(token);

            }, () => {
                    Debug("foo")
                this.props.history.push('/');
            }, this.props.oktaAuth?.getIdToken())
        }
    }

    handleChange = (e) => {
        let currentState = this.state.editUser;
        currentState[e.target.name] = e.target.value;
        this.setState({ editUser: currentState })
    }

    performSearch = (onePassId) => {
        this.setState({ loading: true }, () => {
            let url = `users/${onePassId}`;
            fetchData(url, 'GET', null, null, null, (data) => {
                Debug({ data });
                var user = JSON.parse(data);
                user.User.UserAccount.forEach(a => {
                    a.Permissions = this.getPermissions(a.PermissionId);
                });
                user.User.UserAccount.sort((a, b) => (a.Account > b.Account) ? 1 : -1);
                Debug({user})
                this.setState({ editUser: user, loading: false });
            }, null, null);
        })
       
    }

    saveUser = () => {
        let u = JSON.parse(JSON.stringify(this.state.editUser));
        Debug({ userToSave: u })
        let uu = JSON.parse(sessionStorage.getItem('cci_user'));
        Debug({ requestingUser: uu })
        uu.UserName =uu.Id;
        let userRequest = JSON.stringify({ User: u, RequestingUser: uu});
        this.setState({ saving: true }, () => {
            fetchData('users/update', 'POST', JSON.stringify(userRequest), null, null, () => {
                this.setState({ saving: false })
            }, () => this.setState({ saving: false }))
        })
    }

    handleSelectChange = (value, index) => {
        Debug({ value })
        let u = JSON.parse(JSON.stringify(this.state.editUser));
        u.User.UserAccount[index].Permissions = value?.sort((a, b) => (a.value > b.value) ? 1 : -1);
        let ids = [0];
        if (u.User.UserAccount[index].Permissions) {
            u.User.UserAccount[index].Permissions.forEach(x => ids.push(x.value));
        }
        let permId = ids.reduce(function (a, b) {
            return a + b;
        })
        u.User.UserAccount[index].PermissionId = permId
        this.setState({ editUser: u }, () => {
            Debug({ editUser: this.state.editUser })
        })
    }

    getPermissions = (p) => {
        var results = [];
        if (p & PermissionsEnum.Checks) results.push({ value: 1, label: 'VIEW PAID CHECKS' });
        if (p & PermissionsEnum.Deposits) results.push({ value: 2, label: 'VIEW DEPOSITS' });
        if (p & PermissionsEnum.Download) results.push({ value: 4, label: 'DOWNLOAD FILES' });
        return results;
    }

    render() {
        let permissionOptions = [
            { value: 1, label: 'VIEW PAID CHECKS' },
            { value: 2, label: 'VIEW DEPOSITS' },
            { value: 4, label: 'DOWNLOAD FILES' },
        ]

        return (
            this.state.loading || this.state.saving ?
                <Loading text={this.state.loading ? 'Loading user ...' : 'Saving user ...'}/> :

                <div id='adminFrame'>
                <table>
                        <thead>
                            <tr><th colSpan={2}>{this.state.editUser.User.FirstName} {this.state.editUser.User.LastName} </th></tr>
                            <tr><th colSpan={2} onClick={() => {
                                let currentState = this.state.editUser;
                                currentState.User.IsAdmin = !this.state.editUser.User.IsAdmin;
                                this.setState({ editUser: currentState })
                            }} ><span style={{ marginRight: '16px' }}>Is Admin</span> {this.state.editUser.User.IsAdmin ? <FontAwesomeIcon icon={faCheckSquare} /> : <FontAwesomeIcon icon={faSquare} />}</th>
                                <th></th>
                            </tr>
                        <tr>
                                <th width={140}>Account Number</th>
                                <th>Account Permissions</th>
                        </tr>
                    </thead>
                        <tbody>
                            {this.state.editUser.User.UserAccount.map((u, index) => 
                                <tr key={index}>
                                    <td>{u.Account === -1 ? 'All' : u.Account}</td>
                                    <td><Select
                                        isMulti
                                        noOptionsMessage={() => null}
                                        closeMenuOnSelect={false}
                                        name={'PermissionId'}
                                        options={permissionOptions}
                                        styles={selectStyles}
                                        value={u.Permissions}
                                        placeholder={''}
                                        onChange={(e) => this.handleSelectChange(e, index)}
                                        isSearchable={false}
                                    /></td>
                                </tr>
                            )}
                            
                    </tbody>
                    </table>
                    <div className='row'>
                        <div className={'spacer'}></div>
                        <div className='btn primary' onClick={this.saveUser}> <FontAwesomeIcon icon={faSave} /> Save User</div>
                    </div>
            </div>
        );
    }
}



const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = {
    updateUser
}

export default withOktaAuth(compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ProvisionUser));

