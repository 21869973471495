import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountDownAlt, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { ImagePopup } from './imagePopup';
import { Component } from 'react';
import { numberWithCommas } from '../components/sharedFunctions';
import { fetchData } from './fetchData';
import Debug from './debug';

var moment = require('moment'); // require


export class DepositsList extends Component {


    constructor(props) {
        super(props);
        this.state = { popup: null, showDepositDetails:[], loading:[] }
    }

    getSortIcon = (col) => {
        if (this.props.orderBy !== col) return;
        return this.props.sortAsc ? <FontAwesomeIcon icon={faSortAmountDownAlt} /> : <FontAwesomeIcon icon={faSortAmountDown} />
    }

    showImage = (index, detailIndex) => {
        this.setState({
            popup: <ImagePopup transaction={this.props.transactions[index]} hide={this.hideImage} detailIndex={detailIndex} />
        })
    }

    toggleShowDepositDetails = (index) => {
        let t = JSON.parse(JSON.stringify(this.props.transactions[index]));
        let body = JSON.stringify({ AccountNumber: t.AccountNumber, IPDate: t.IPDate, CPCS: t.CPCS, IPAmount: t.IPAmount });
        let values = JSON.parse(JSON.stringify(this.state.showDepositDetails));
        let loading = JSON.parse(JSON.stringify(this.state.loading));
        values[index] = values[index] ? false : true;
        loading[index] = true;
        this.setState({ showDepositDetails: values, loading:loading }, () => {
            fetchData('deposits/items', 'POST', body, null, null, (items) => {
                Debug({items})
                loading[index] = false;
                t.DepositItems = JSON.parse(items);
                this.props.updateTransaction(index, t);
                this.setState({ loading: loading })

            }, () => {
                    loading[index] = false;
                    values[index] = false;
                    this.setState({ showDepositDetails: values, loading: loading  })
            })

        })
        
    }

    hideImage = (id) => {
        this.setState({ popup: null })
    }


    render() {
        return (
            <Fragment>
                <table>
                    <thead>
                        <tr>
                            <th colSpan={4}>Item count: {numberWithCommas(this.props.transactions.length)}</th>
                        </tr>
                        <tr>
                            <th>Item #</th>
                            <th onClick={() => this.props.sort('IPDate')}>Deposit Date {this.getSortIcon('IPDate')}</th>
                            <th onClick={() => this.props.sort('CPCS')}>Bank Sequence # {this.getSortIcon('CPCS')} </th>
                            <th onClick={() => this.props.sort('IPAmount')}>Amount {this.getSortIcon('IPAmount')} </th>
                        </tr>
                    </thead>
                    <tbody>

                        {this.props.transactions && this.props.transactions.length > 0 ? this.props.transactions.map((t, index) =>
                            <Fragment key={index} >
                                <tr title='Click to view deposit' onClick={() => this.toggleShowDepositDetails(index)}>
                                    <td>{index + 1}</td>
                                    <td>{moment(t.IPDate).format('MM/DD/YYYY')}</td>
                                    <td>{t.CPCS}</td>
                                    <td>{numberWithCommas(`$${(parseFloat(t.IPAmount) * .01).toFixed(2)}`)}</td>
                            </tr>
                                {
                                    this.state.showDepositDetails[index] ?
                                        this.state.loading[index] ?
                                            <tr className='depositDetailRow' >
                                                <td colSpan={4}>Loading...</td>
                                            </tr> :
                                        t.DepositItems.map((d, dIndex) =>
                                            <tr key={dIndex} className='depositDetailRow' onClick={() => this.showImage(index, dIndex)}>
                                                <td></td>
                                                <td>{d.Description}</td>
                                                <td>{d.CPCS}</td>
                                                <td>{d.Description === 'Deposit Ticket' ? '' : numberWithCommas(`$${(parseFloat(d.Amount) * .01).toFixed(2)}`)}</td>
                                            </tr>
                                        )
                                         : null
                                }
                            </Fragment>
                        ) :
                            <tr className='no-hover'>
                                <td colSpan={4}> No transactions found.</td>
                            </tr>

                        }
                    </tbody>
                </table>
                {this.state.popup}
            </Fragment>
        )
    }
}

export default DepositsList;