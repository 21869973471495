import React, { Fragment, Component } from 'react';
import { FilesList } from '../components/filesList';
import Debug from '../components/debug';
import { fetchData } from '../components/fetchData';
import DownloadSearchform from '../components/downloadSearchForm';
import { withRouter } from 'react-router';
import { Loading } from '../components/loading';
import { Layout } from '../components/Layout';

export class Downloads extends Component {

    constructor(props) {
        super(props);
        this.state = { zipFiles: [], orderBy: 'Filename', sortAsc: true, searching: false, yearMonth: '' }
    }

    sort = (colClicked) => {
        let order = JSON.parse(JSON.stringify(this.state.orderBy));
        let dir = JSON.parse(JSON.stringify(this.state.sortAsc));
        let zipFiles = JSON.parse(JSON.stringify(this.state.zipFiles));
        if (colClicked === this.state.orderBy) {
            dir = !dir;
        } else {
            order = colClicked;
            dir = true;
        }

        zipFiles = this.sortzipFiles(zipFiles, dir, order);

        this.setState({ zipFiles: zipFiles, orderBy: order, sortAsc: dir });
    }

    sortzipFiles(zipFiles, sortAsc, order) {
        if (sortAsc) {
            return zipFiles.sort((a, b) => (a[order] > b[order]) ? 1 : -1);
        } else {
            return zipFiles.sort((a, b) => (a[order] > b[order]) ? -1 : 1);
        }
    }

    search = (monthYear) => {
        this.setState({ searching: true }, () => {
            Debug({ searching: monthYear });
            fetchData('downloads/search', 'POST', JSON.stringify({ value: monthYear, label: monthYear }), null, null, (data) => {
                let zipfiles = this.sortzipFiles(JSON.parse(data), this.state.sortAsc, this.state.orderBy);
                this.setState({ zipFiles: zipfiles, searching: false, yearMonth: monthYear });
            }, () => this.setState({ searching: false }))
        })
    }

    clearFiles = () => {
        this.setState({ zipFiles: [], orderBy: 'Filename', sortAsc: true })
    }

    render() {
        return (
            <Layout {... this.props} version={process.env.REACT_APP_APPVERSION}>  
                <Fragment>
                    <h1>Search for Available Monthly Files</h1>
                    <p>Select a month and year to view.</p>
                    <div id='searchWindow'>
                        <DownloadSearchform search={this.search} clearFiles={this.clearFiles} />
                        {this.state.searching ?
                            <Loading text={'Retrieving files ...'} /> :
                            <FilesList zipFiles={this.state.zipFiles} sort={this.sort} orderBy={this.state.orderBy} sortAsc={this.state.sortAsc} yearMonth={this.state.yearMonth} />

                        }
                    </div>

                </Fragment> 
                </Layout>
        )
    }
}


export default withRouter(Downloads);
