import React, { Component, Fragment } from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Debug from './debug';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateUser } from '../redux/actions';
import { Button } from '@material-ui/core';


const selectStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '#777',
        backgroundColor: state.isSelected ? '#88bd40' : '#fff',
        ':hover': {
            color: '#66a326',
            backgroundColor:'#c2dd9e',
        }
    }),
    input: (styles) => ({
        ...styles,
        height: '34px !important',
        outline: 'none !important',
        borderColor: "#88bd40",


        ':hover': {
            borderColor: 'hsl(0,0%,80%) !important',
            borderWidth: '1px !important',
            boxShadow: 'none !important',
        },
        ':focus': {
            borderColor: 'hsl(0,0%,80%) !important',
            borderWidth: '1px !important',
            boxShadow: 'none !important',
        }
    }),
    control: (styles) => ({
        ...styles,
        height: '34px !important',
        outline: 'none !important',
        boxShadow: 'none',
        borderColor: 'hsl(0,0%,80%) !important',
        ':hover': {
            border: '1px solid hsl(0,0%, 80 %) !important',
            borderColor: 'hsl(0,0%,80%) !important',
            borderWidth: '1px !important',
            boxShadow: 'none !important',
        },
        ':focus': {
            borderColor: 'hsl(0,0%,80%) !important',
            borderWidth: '1px !important',
            boxShadow: 'none !important',
        }
    })
}


const defaultState = {
    accountNumberLabel:'',
    formValues: {
    AccountNumber: '', Amount: '', AmountComparison: 0,
    BeginningPaidDate: '', EndingPaidDate: '',
    BankSequenceNumber: '', CheckNumberLow: '', CheckNumberHigh: '',
}}

export class Searchform extends Component {

    constructor(props) {
        super(props);
        this.state = this.getDefaultState();
    }

    getDefaultState = () => {
        return JSON.parse(JSON.stringify(defaultState));
    }

    populateAccounts(search) {
        if (this.props.user.IsRegions) {
            return this.props.accounts.filter(i => i.label.toLowerCase().includes(search.toLowerCase()))
        }
        else {
            return this.props.accounts;
        }
 
    }

    promiseOptions = (inputValue) =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(this.populateAccounts(inputValue));
            }, 1000);
        });

    
    handleChange = (e) => {
        let currentState = this.state.formValues;
        currentState[e.target.name] = e.target.value;
        this.setState({ formValues: currentState })
    }

    handleSelectChange = (propName, value) => {
        let currentState = this.state.formValues;
        currentState[propName] = value.value;
        if (propName === 'AccountNumber') this.setState({ accountNumberLabel: value.label })
        this.setState({ formValues: currentState }, () => Debug({ formValues: this.state.formValues }))
    }

    handleDateChange = (propName, value) => {
        let currentState = this.state.formValues;
        currentState[propName] = value;
        this.setState({ formValues: currentState })
    }

    performSearch = () => {
        var searchValues = Object.assign({}, this.state.formValues);
        this.props.search(searchValues);
    }

    clearAll = () => {
        this.setState(this.getDefaultState(), this.props.clearTransactions)
    }

    isValidBankSequence = () => {
        return this.state.formValues.BankSequenceNumber === "" || this.state.formValues.BankSequenceNumber.length === 10
    }

    render() {
        let amountComparisonOptions = [
            { value: 0, label: 'Equal To' },
            { value: 1, label: 'Greater Than' },
            { value: 2, label: 'Greater Than Or Equal To' },
            { value: 3, label: 'Less Than' },
            { value: 4, label: 'Less Than Or Equal To' }
        ]

        return (
            <Fragment>
            <form id='searchForm'>
                
                <div className='row'>
                    <div className='label'>Account Number:</div>
                    <div className='control no-border'>
                        {this.props.user.IsRegions === true ?
                            <AsyncSelect
                                name={'AccountNumber'}
                                styles={selectStyles}
                                loadOptions={this.promiseOptions}
                                    placeholder={''}
                                    value={{
                                        label: this.state.formValues.AccountNumber, value: this.state.formValues.AccountNumber
                                    }}
                                onChange={(e) => this.handleSelectChange('AccountNumber', e)}
                            /> :
                            <Select
                                name={'AccountNumber'}
                                options={this.props.accounts}
                                styles={selectStyles}
                                    value={{
                                        label: this.state.accountNumberLabel, value: this.state.formValues.AccountNumber
                                    }}
                                placeholder={''}
                                onChange={(e) => this.handleSelectChange('AccountNumber', e)}
                                isSearchable={false}
                            />
                        }
                    </div>
                </div>
                <div className='row'>
                        <div className='label'>Amount:</div>
                        <div className='control'><TextField name='Amount' type='text' label ='' InputLabelProps={{
                            shrink: true,
                        }} value={this.state.formValues.Amount} onChange={this.handleChange}/></div>
                </div>
                <div className='row'>
                    <div className='label'>Amount Comparison:</div>
                    <div className='control no-border'>
                        <Select
                            name={'AmountComparison'}
                            options={amountComparisonOptions}
                            styles={selectStyles}
                            value={amountComparisonOptions[this.state.formValues.AmountComparison]}
                            placeholder={''}
                            onChange={(e) => this.handleSelectChange('AmountComparison', e)}
                            isSearchable={false}
                        />
                        
                    </div>
                </div>
                <div className='row'>
                    <div className='label'>Beginning Paid Date:</div>
                    <div className='control'>
                        <TextField
                            id="BeginningPaidDate"
                            name="BeginningPaidDate"
                            label=""
                            type="date"
                            value={this.state.formValues.BeginningPaidDate}
                            onChange={this.handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='label'>Ending Paid Date:</div>
                    <div className='control'>
                        <TextField
                            id="EndingPaidDate"
                            name="EndingPaidDate"
                            label=""
                            type="date"
                            value={this.state.formValues.EndingPaidDate}
                            onChange={this.handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    </div>
                    <div className='row' style={!this.isValidBankSequence() ? { 'marginBottom': '1.5rem' } : { 'marginBottom': '1rem' }}>
                    <div className='label'>Bank Sequence Number:</div>
                        <div className='control'><TextField name='BankSequenceNumber' type='text' label='' InputLabelProps={{
                            shrink: true,
                        }} value={this.state.formValues.BankSequenceNumber} onChange={this.handleChange} error={!this.isValidBankSequence()}
                            helperText={!this.isValidBankSequence() ? "10 digits are required" : ""}
                         /></div>
                </div>

                {
                    this.props.isDeposits ? null :
                    <Fragment>
                        <div className='row'>
                            <div className='label'>Check Number Low:</div>
                                    <div className='control'><TextField name='CheckNumberLow' type='text' label='' InputLabelProps={{
                                        shrink: true,
                                    }} value={this.state.formValues.CheckNumberLow} onChange={this.handleChange} /></div>
                        </div>
                        <div className='row'>
                            <div className='label'>Check Number High:</div>
                                    <div className='control'><TextField name='CheckNumberHigh' type='text' label='' InputLabelProps={{
                                        shrink: true,
                                    }} value={this.state.formValues.CheckNumberHigh} onChange={this.handleChange} /></div>
                        </div>
                    </Fragment>
                }

                <div className='row'>
                    <div className={'spacer'}></div>
                        <Button className='btn' onClick={this.clearAll}> <FontAwesomeIcon icon={faUndoAlt} /> Clear</Button>
                        <Button className='btn primary' onClick={this.performSearch} disabled={!this.isValidBankSequence()}> <FontAwesomeIcon icon={faSearch} /> Search</Button>
                </div>
                </form>
            </Fragment>
        )
    }
    
}


const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = {
    updateUser
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Searchform);