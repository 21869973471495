export const UPDATE_USER = 'UPDATE_USER';

export function updateUser(user) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: UPDATE_USER,
                user: user
            });
            resolve()
        });
    }
}


export const userReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_USER:
            return action.user;
        default:
            return state;
    }
}